<template>
  <div>
    <div class="row">
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('layout.filters.label') }}</span>
          <template slot="body">
            <filters
              :loading="loading"
              :actions="filterActions"
              @filter="filterData"
              @clear-filters="filterData(null)"
            />
          </template>
        </va-collapse>
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('email_templates.index')">
          <remote-table
            class="table-hover table-striped"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :search="searchOptions"
            :action-options="actions"
            :pagination="pagination"
            :params="serverParams"
            :queries="serverParams.queries"
            sort-field="name"
            sort-type="asc"
            crud-links="emailTemplates"
            @update-data="updateData"
            @search-data="searchByName"
            @delete-item="tryDelete"
            @download-item="prepareDownload"
          />
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'email-templates-index',
  components: {
    Filters, RemoteTable,
  },
  data () {
    return {
      error: false,
      tableData: [],
      pagination: {},
      actions: ['view', 'edit', 'delete'],
      searchQuery: '',
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      serverParams: {
        queries: '',
      },
      filterActions: ['regions', 'name'],
    }
  },
  computed: {
    tableFields () {
      return [
        {
          name: 'region',
          title: this.$t('tables.headings.region'),
          sortField: 'Regions.name',
          callback: this.regionLabel,
        },
        {
          name: 'title',
          title: this.$t('tables.headings.title'),
          sortField: 'title',
          callback: this.translatedLabel,
        },
        {
          name: 'type',
          title: this.$t('tables.headings.type'),
          sortField: 'EmailTypes.name',
          callback: this.getTypeLabel,
        },
        {
          name: 'layout',
          title: this.$t('tables.headings.layout'),
          sortField: 'EmailLayouts.name',
          callback: this.getLayoutLabel,
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
    emailLayoutsList () {
      return [
        {
          name: this.$t('email.layouts.white_layout'),
          value: 1,
        },
        {
          name: this.$t('email.layouts.gray_layout'),
          value: 2,
        },
      ]
    },
    emailTypesList () {
      return [
        {
          name: this.$t('email.types.certificate'),
          value: 1,
        },
        {
          name: this.$t('email.types.confirm'),
          value: 2,
        },
        {
          name: this.$t('email.types.forgot'),
          value: 3,
        },
        {
          name: this.$t('email.types.message'),
          value: 4,
        },
        {
          name: this.$t('email.types.training'),
          value: 5,
        },
      ]
    },
  },
  methods: {
    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.region) {
        queries += '&region=' + filters.region
      }
      if (filters.name) {
        queries += '&name=' + filters.name
      }

      this.serverParams.queries = queries
    },
    regionLabel (region) {
      let text = this.$t('layout.selects.all_regions')
      if (region) {
        text = this.$t(region.name)
      }
      return text
    },
    getLayoutLabel (label) {
      let layout = this.emailLayoutsList.find(x => x.value === label)
      if (!layout) layout = { name: '' }
      return layout.name
    },
    getTypeLabel (label) {
      let type = this.emailTypesList.find(x => x.value === label)
      if (!type) type = { name: '' }
      return type.name
    },
    translatedLabel (label) {
      return this.$t(label)
    },
    truncateMessage (text) {
      const stop = 100
      return text.slice(0, stop) + (stop < text.length ? '...' : '')
    },
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.user) {
          d.user = {
            name: '',
          }
        }
        let status = this.notificationsStatus.find(s => s.value === d.status)
        status = status || { name: '', value: -1 }
        d.status = this.$t(status.name)
      }
      return data
    },
    apiUrl (params) {
      let route = 'email-templates'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        const field = params.sort.field
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.queries) {
        route += params.queries
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = response.data.data
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (name === '') return this.updateData()

      let response = false
      try {
        response = await this.$http.get('email-templates?q=' + name)
      } catch (err) {
        // console.log('Error searching notifications', err)
        this.loading = false
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.data.length || 0
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('email-templates/' + item.id)
      } catch (err) {
        // console.log('Error deleting notifications', err)
        this.loading = false
        return
      }
      this.updateData()
    },
    async prepareDownload (format) {
      const prepareRoute = 'email-templates/download'
      const downloadRoute = 'email-templates/download/'

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
